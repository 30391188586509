.bb-header-1 {
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  border-bottom: 1px solid #d2ddec;
  div.col-sm-12.col-md-4,
  h2 {
    width: 100%;
  }
}

.site-appearance {
  position: relative;
  max-width: 800px;
  .bd-highlight.color-input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .ant-col.ant-form-item-label {
    text-align: left;
    width: 100% !important;
    font-weight: 600;
  }
  .input-preview-image {
    width: 100%;
    height: 140px;
    border: 1px solid #d2ddec;
    border-radius: 10px;
    margin-top: 6px;
    display: none;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    background-position: center !important;
  }
  .form-file-block {
    position: relative;
    overflow: hidden;
    cursor: pointer;
  }
  .form-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
  .form-file-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    padding: 0 12px;
    height: 100%;
    font-size: 14px;
    color: #95aac9;
  }

  .input-file-name {
    font-size: 0.9rem;
  }
  .form-input {
    width: 100%;
    margin-top: 6px;
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 0.75rem;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #12263f;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d2ddec;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .form-input-block {
    position: relative;
  }

  .input-title {
    position: relative;
    font-size: 1rem;
    font-weight: 600;
  }
  .input-desc {
    position: relative;
    color: #95aac9;
    font-size: 0.9375rem;
    margin-top: 2px;
  }
}

.auth-container {
  label {
    font-weight: 600;
    margin-bottom: 4px !important;
  }
}

.swal2-title {
  color: #262626 !important;
}
.swal2-styled.swal2-confirm {
  background-color: #262626 !important;
  color: white !important;
}

.swal2-styled.swal2-deny {
  background-color: #8e8e8e !important;
  color: #fff !important;
}

.input-form-container.staff {
  height: calc(100vh - 100px);
  margin-top: 0 !important;
  .staff-form-block {
    margin-bottom: 0;
    .col-12 .ant-form-item-control-input-content {
      max-width: calc(50% - 15px);
    }
    .ant-form-item-label {
      margin-bottom: 5px !important;
      font-weight: 600 !important;
      font-size: 1rem !important;
      color: #12263f !important;
    }
  }
}

.ant-layout-content {
  position: relative;
}
.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-selected {
  color: #262626 !important;
  font-weight: 600;
}

.ant-menu-inline.ant-menu .ant-menu-item.ant-menu-item-selected {
  color: #262626 !important;
  font-weight: 600;
}

@media (max-width: 480px) {
  .page-form_inputFormList__2w8hg {
    position: relative !important;
    width: 95% !important;
  }

  .input-form-container .form-footer {
    position: relative !important;
    width: 96% !important;
    left: 0 !important;
  }
  [data-vb-theme='default'] .ant-modal-wrap {
    position: absolute !important;
  }
  [data-vb-theme='default'] .ant-modal-title {
    font-size: 14px !important;
  }
  .ant-form-item-control-input-content
    .ant-select
    .ant-select-lg
    .ant-select-single
    .ant-select-show-arrow
    .ant-select-show-search {
    width: 100% !important;
  }

  .ant-table-tbody
    .ant-table-row
    .ant-table-row-level-0
    .ant-table-cell
    .ant-badge
    .ant-badge-status
    .ant-badge-not-a-wrapper {
    width: max-content !important;
  }
}
