@import 'mixins.scss';

// .container {
//   width: 100%;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center;
//   flex-shrink: 0;

//   &.white {
//     background: $white;
//   }

//   &.gray {
//     background: $gray-1;
//   }
// }

// .containerInner {
//   max-width: 40rem;
//   height: 100%;
//   width: 100%;
// }

// .logo {
//   // height: 80px;
//   width: 200px;
//   margin: 16px auto;
// }

// dark theme
[data-vb-theme='dark'] {
  .container {
    background: transparent !important;
  }
}

// .authCard{
//   position: absolute;
//   height: 100%;
//   top: 0;
//   left: 80px;
//   width: 100%;
//   max-width: 35rem;
//   display: flex;
// }

// .centerCard {
//   min-height: 100vh;
//   display: flex;
//   width: 100%;
//   align-items: center;
// }

.innerCard{
  text-align: center;
  width: 100%;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 630px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-width: 430px;
  background: #fff;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
}

.logo {
  max-width: 180px;
  width: 100%;
  margin-bottom: 10px;
}

.links {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    display: inline-block;
    margin: 0px 5px;
  }
}

.dot {
  display: inline-block;
  margin: 0 3px;
  &::after {
    display: inline-block;
    content: '·';
    color: #a1a1c2;
  }
}

.bgImage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.formcon {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}