@import 'mixins.scss';

#nprogress .spinner {
  display: none;
}

.ant-form-item {
  margin-bottom: 14px !important;
}

.ant-form-item-label {
  padding: 0 0 2px 0 !important;
}

.ant-table-cell {
  font-size: 1rem;
}

.ant-drawer-body {
  background-color: $gray-1;
}

code {
  padding: 5px 10px;
  display: inline-block;
}

.pin-container {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 8px;
  justify-content: center;
}

.pin-field {
  width: 32px;
  height: 32px;
  font-size: 16px;
  text-align: center;
  outline: none;
  border-radius: 6px;
  border: 1px solid $dark-gray-1;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}

.pin-field-large {
  width: 36px;
  height: 36px;
  font-size: 18px;
  text-align: center;
  outline: none;
  border-radius: 6px;
  border: 1px solid $dark-gray-1;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;

  &:focus {
    outline: none;
    box-shadow: 0 0 7px rgba($blue, 0.5);
    border: 1px solid $blue;
    transform: scale(1.05);
  }
}

.pin-field-complete {
  border: 1px solid $green;
  color: $green;

  &[disabled] {
    background: rgba($green, 0.1);
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.border-gray-3 {
  border-color: #f2f3f7 !important;
}

.set-bottom .ant-menu-item.ant-menu-item-only-child span {
  padding-left: 8px !important;
}

.logo-img {
  width: 160px !important;
  height: auto !important;
}

.layout-icon-selected {
  border: 2px solid #262626 !important;
}

.form-drawer {
  .ant-drawer-content-wrapper {
    max-width: 900px;
    .ant-drawer-body {
      padding: 5px !important;
      background-color: #fff !important;
    }
  }
}

.form-drawer.small {
  .ant-drawer-content-wrapper {
    max-width: 850px;
  }
}

.form-drawer.v-small {
  .ant-drawer-content-wrapper {
    max-width: 700px;
  }
}

.input-form-container.coupon {
  height: calc(100vh - 100px);
  overflow-y: scroll;
  margin-top: 0 !important;
  .coupon-form-block {
    margin-bottom: 120px;
    .col-md-12 .ant-form-item-control-input-content {
      max-width: calc(50% - 15px);
    }
    .ant-form-item-label {
      margin-bottom: 5px !important;
      font-weight: 600 !important;
      font-size: 1rem !important;
      color: #12263f !important;
    }
  }
}

.input-form-container {
  margin-top: 16px !important;
  .card {
    background-color: #fff !important;
    border: 0 !important;
    border-bottom: 1px dashed #d2ddec !important;
    margin: 0 1.66rem;
    .card-body {
      display: flex;
      border-color: unset !important;
      box-shadow: none !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .last-card.row {
        margin-right: 0 !important;
        margin-bottom: 120px !important;
      }
      .row.d-header {
        width: 100%;
        max-width: 300px;
        margin-right: 24px;
      }
      .row {
        width: 100%;
        // h4.header{
        //   max-width: 240px;
        // }
        .ant-form-item-label {
          margin-bottom: 5px !important;
          font-weight: 600 !important;
          font-size: 1rem !important;
          color: #12263f !important;
        }
        .dashborder.top0 {
          padding-top: 0 !important;
        }
        .dashborder {
          padding-bottom: 20px;
          padding-top: 20px;
          border-bottom: 1px dashed #d2ddec !important;
        }
      }
    }
  }
  .form-footer {
    box-shadow: 0 -6px 5px -4px rgba(0, 0, 0, 0.1);
    left: 18px;
    bottom: 0 !important;
    position: absolute;
    padding: 24px 15px;
    border-top: 1px dashed #d2ddec;
    z-index: 900;
    background-color: #f9fbfd;
    transition: box-shadow 0.2s linear;
    width: calc(100% - 36px);
    .center-align {
      text-align: center !important;
    }
  }
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: 14px !important;
}

[data-vb-theme='default'] .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #262626;
  font-weight: 500;
}

[data-vb-theme='default'] .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color: #8e8e8e;
}

[data-vb-theme='default'] .ant-tabs-ink-bar {
  background: #262626;
}

[data-vb-theme='default'] .ant-switch {
  background-color: #8e8e8e;
}

[data-vb-theme='default'] .ant-switch.ant-switch-checked {
  background: #262626;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background-color: #8e8e8e !important;
  color: white;
}

[data-vb-theme='default'] .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #efefef;
}

[data-vb-theme='default'] .ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    color: #262626 !important;
    font-weight: 600;
  }
}
.ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
  color: #262626 !important;
  font-weight: 600;
}

[data-vb-theme='default'] ul.ant-menu-vertical .ant-menu-item-selected {
  background-color: #8e8e8e !important;
  color: #fff !important;
  a {
    color: #fff !important;
  }
}

ul.ant-menu-vertical li.ant-menu-item.ant-menu-item-only-child.ant-menu-item-active {
  background: #efefef !important;
}

.hide {
  display: none;
}

b,
strong {
  font-weight: 600 !important;
}

.no-border {
  border: 0 !important;
  box-shadow: none !important;
}

.website-widget {
  .kit__utils__heading {
    padding-bottom: 16px;
    border-bottom: 1px solid #d2ddec;
    display: flex;
    width: 100%;
  }
  h5 {
    width: 100%;
  }
  .d-flex {
    display: flex;
  }
  .widget_layout {
    .ant-radio-wrapper {
      display: block !important;
      margin: 10px 0;
      color: #262626;
    }
  }
}

.timing-billing {
  font-size: 14px !important;
}

.font-weight-semi-bold {
  font-weight: 500 !important;
}

.form-footer {
  box-shadow: 0 -6px 5px -4px rgba(0, 0, 0, 0.1);
  left: 18px;
  position: sticky;
  bottom: 0 !important;
  padding: 24px 15px;
  border-top: 1px dashed #d2ddec;
  z-index: 900;
  background-color: #f9fbfd;
  transition: box-shadow 0.2s linear;
  width: calc(100% - 48px);
  text-align: center;
  .center-align {
    text-align: center !important;
  }
}

.form-footer.w100 {
  width: 100% !important;
}

.btn-play-pause {
  padding-left: 32px !important;
  .fe.btn-pause-play {
    font-size: 18px;
    min-width: 16px;
    position: absolute !important;
    top: 10px !important;
    left: 8px !important;
  }
}

.ant-layout-content {
  position: relative;
}
.ant-menu.ant-menu-sub.ant-menu-inline .ant-menu-item.ant-menu-item-selected {
  color: #262626 !important;
  font-weight: 600;
}

@media (max-width: 480px) {
  [data-vb-theme='default'] .ant-drawer-wrapper-body {
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    width: 60% !important;
    height: 100% !important;
  }
  .input-form-container .form-footer {
    position: relative !important;
    width: calc(100%) !important;
    left: 0 !important;
  }
}
